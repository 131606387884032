$c1:#797D62;
$c2:#9B9B7A;
$c3:#D9AE94;
$c4: #efe0ba;
$c5:#FFCB69;
$c6:#D08C60;
$c7:#997B66;

.main{
  width: 100%;
  height: 100%;
}

body {
  margin-bottom: 25px;
  background-color: $c4;
}


.alert-enter {
  opacity: 0;
  transform: scale(0.5);
}
.alert-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 200ms, transform 500ms;
}



.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition-timing-function: cubic-bezier(.17,.67,.83,.67);
  transition: opacity 2000ms;

}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition-timing-function: cubic-bezier(.17,.67,.83,.67);
  transition: opacity 200ms;
}
