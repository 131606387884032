.container{
  display: flex;
  flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.imageHolder {
    min-width: 350px;
    width: 100vw;
}

/* For width 400px and larger: */
@media only screen and (min-width: 400px) {
    .imageHolder{
        width: 33vw;
    }
}

.image{
    flex: 0 1;
    padding-top: 10px;
    max-width: 100%;
    overflow: hidden;
}

img {
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
}


h1{
    font-family: Hack, sans-serif;
    color: #997B66;
    text-shadow: #c5a999 0px 1px;
}

