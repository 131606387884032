.counter {
  text-align: right;
  font-family: Arial, sans-serif;
  margin-right: 20px;
}

.counter h1 {
  font-size: 1rem;
  display: inline;
  margin: 0;
  font-family: Hack, sans-serif;
  color: #775d4a;
  text-shadow: none;
}

.counter-value {

  font-size: 2rem;
  font-weight: bold;
  color: #ff6b6b;
}