.aquarium-container{
  position: relative;

  display: inline-block;
  margin-top: 50px;
  margin-bottom: 0;
}

.aquarium {
  background-color: #8294da;
  position: relative;
  width: 90vw;
  height: 65vh;
  overflow: hidden;
  border-radius: 15px;
}

.flex-container{
  display: flex;
  width: 100%;
  height: 100%;
}

.block{
  height: 100%;
  width: 100px;
}

.flex-container2{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.block2{
  height: 100px;
  width: 100%;
}
.item-spawnable{
  margin-bottom: auto;
  flex: 1;
  align-self: stretch;
  text-align: left;
  position: relative;

}


.item {
  position : absolute;
  transform: translate(-100%, -100%);
}

.item-static {
  position: absolute;
  max-width: 280px;
  transform: translate(0%,-100%);
}

@font-face {
  font-family: goofyFont;
  src: url(../fonts/Rumpelstiltskin-EPdj.ttf);
}

.chat {
  display: inline-block;
  top: 50px;
  position: relative;
  width: 90vw;
  height: 30vh;
  margin-bottom: 20px;
  background-color: #D9AE94;
  border-radius: 15px;


  text-align: left;
  font-size: clamp(1rem, 0.8rem + 1vw, 2rem);
  overflow-y: auto;
  overflow-x: hidden;

}

.chat .text {
  margin: 10px;
  font-size: clamp(1.3rem, 1rem + 1vw, 2rem);

  font-family: goofyFont;
  color:white;
}

.chat .note {
  text-align: center;

  margin: 10px;

  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color:black;
}

