.content{
  width: 100vw;
  height: 100vh;
}

.popup {
  z-index: 10;
  font-weight: 700;
  text-align: center;
  font-size: clamp(2.5rem, 2.5rem + 1vw, 4rem);

  font-family: Hack, sans-serif;
  text-transform: uppercase;
  background: linear-gradient(90deg, #efe0ba 0%, #d0a76a 15%, #cc6131 50%, #d0a76a 85%, #efe0ba 100%) no-repeat;
  background-position-x: -300%;
  letter-spacing: 5px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 75%;
  animation: shine 4s cubic-bezier(.04,.33,.97,.62) infinite;
  animation-iteration-count: 1;
  position: relative;
}

@keyframes shine {
  0% {
    background-position-x: -300%;
  }
  100% {
    background-position-x: 300%;
  }
}


.EddieButton {
  background-color: #618df8;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  list-style: none;
  outline: none;
  display: inline-block;
  padding: 10px 16px;
  text-decoration: none;
  transition: color 100ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: 20px;
  width: 80%;
  max-width: 300px;
}

.EddieButton:hover,
.EddieButton:focus {
  background-color: #3a65c5;
}

.lottieContainer {
  width: 100%;
  margin-top: 20vh;
}

.lottieHeart {
  z-index: 1;
  width: 100vw;
  max-width: 500px;
  margin: auto;
}